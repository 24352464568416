/* eslint-disable max-len */
import {logger} from '@mol-fe/mol-fe-client-logger';
import React, {useCallback, useEffect, useState} from 'react';
import {loadUserComments} from '../../common/comments';
import {useMount} from '../../hooks/useMount';
import Spinner from '../Spinner';
import styles from './ProfileCommentList.module.scss';
import UserComment from './UserComment';

const PAGE_SIZE = 100;
const PERIODS = [
  {
    text: 'In the last 7 days...',
    type: 'week'
  },
  {
    text: 'In the last 30 days...',
    type: 'month'
  },
  {
    text: 'In the last year...',
    type: 'year'
  },
  {
    text: 'Archive',
    type: 'archive'
  }
];

export default ({userId, username}) => {
  const [period, setPeriod] = useState('week');
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMounted = useMount();

  const onLoadUserComments = useCallback(async (value) => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const result = await loadUserComments(userId, value, PAGE_SIZE);

      if (isMounted.current) {
        setComments(result);
      }
    } catch (error) {
      // Stay silent if we can't load
      logger.warn('onLoadUserComments: error', error);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  }, [setLoading, isMounted, setComments]);

  useEffect(() => {
    onLoadUserComments(period);
  }, [period]);

  const onChangePeriod = (value) => setPeriod(value);

  return (
    <div className={styles.container} id='profile-comments-container'>
      <div className={styles.heading}>
        <h2>{username}&apos;s Comments</h2>
      </div>

      {PERIODS.map(({text, type}) => (
        <div className={styles.tab} key={type}>
          <button className={styles.tabButtons} onClick={() => onChangePeriod(type)}>{text}</button>
          {period === type &&
            <React.Fragment>
              {comments.length === 0 && (
                <p className={styles.noComments}>
                  No comments have been found for this selection.
                </p>
              )}
              {comments.map((comment) => (
                <UserComment comment={comment} key={comment.id} />
              ))}
            </React.Fragment>
          }
        </div>
      ))}

      <p className={styles.views}>
        The opinions and views expressed in the comments section are solely those of the individual users and do not represent or reflect the opinions, views, or positions of MailOnline. MailOnline does not endorse, support, or verify the accuracy of any user-generated content.
      </p>

      {loading && <Spinner />}
    </div>
  );
};
