import {logger} from '@mol-fe/mol-fe-client-logger';
import axios from 'axios';
import {clearCommentStore, saveCommentStore} from './cookies';
import {isShoutEnabled} from './shoutChannels';
import {trackCommentReplySubmit, trackCommentSubmit, trackCommentReplySubmitSuccess, trackCommentSubmitSuccess, trackVoteUp, trackVoteDown, trackCommentLoginRedirect} from './tracking';

const DEFAULT_LIMIT = 3;
const LOGIN_URL = '/registration/login.html?reg_source=mweb_comment&targetUrl=';

const buildQueryParams = (query, addShout = false) => {
  const params = new URLSearchParams();

  params.set('max', query.limit || DEFAULT_LIMIT);

  if (query.offset) {
    params.set('offset', query.offset);
  } else {
    params.set('offset', 0);
  }

  if (query.period) {
    params.set('period', query.period);
  }

  switch (query.tab) {
  case 'bestRated':
    params.set('sort', 'voteRating');
    params.set('order', 'desc');
    break;
  case 'oldest':
    params.set('order', 'asc');
    break;
  case 'worstRated':
    params.set('sort', 'voteRating');
    params.set('order', 'asc');
    break;
  case 'newest':
  default:
    params.set('order', 'desc');
    break;
  }

  if (addShout) {
    params.set('rcCache', 'shout');
  }

  return params.toString();
};

export const loadComments = async (articleId, query) => {
  const useShout = isShoutEnabled();
  const queryString = buildQueryParams(query, useShout);

  const url = `/reader-comments/p/asset/readcomments/${articleId}?${queryString}`;

  try {
    const response = await axios.get(url);

    if (response.status === 200) {
      const {payload} = response.data;

      return {
        result: payload.page,
        totalCount: payload.parentCommentsCount
      };
    }
  } catch (error) {
    logger.warn('loadComments: error', error);
  }

  return {
    result: [],
    totalCount: 0
  };
};

export const loadMyComments = async (articleId, query) => {
  const useShout = isShoutEnabled();
  const queryString = buildQueryParams(query, useShout);
  const userId = query.userId;

  const url = `/reader-comments/p/asset/readmycomments/${articleId}/${userId}?${queryString}`;

  try {
    const response = await axios.get(url);

    if (response.status === 200) {
      const {payload} = response.data;

      return {
        result: payload.page,
        totalCount: payload.parentCommentsCount
      };
    }
  } catch (error) {
    logger.warn('loadMyComments: error', error);
  }

  return {
    result: [],
    totalCount: 0
  };
};

export const loadUserComments = async (id, period, limit) => {
  const queryString = buildQueryParams({
    limit,
    period,
    tab: 'newest'
  });

  const url = `/reader-comments/p/user/readcomments/${id}?${queryString}`;

  try {
    const response = await axios.get(url);

    if (response.status === 200) {
      const {payload} = response.data;

      return payload.page;
    }
  } catch (error) {
    logger.warn('loadUserComments: error', error);
  }

  return [];
};

export const loadCommentReplies = async (commentId, offset) => {
  const url = `/reader-comments/p/comment/morereplies/${commentId}?start=${offset}`;

  try {
    const response = await axios.get(url);

    if (response.status === 200) {
      const {payload} = response.data;

      return payload;
    }
  } catch (error) {
    logger.error('loadCommentReplies: error', error);
  }

  return null;
};

export const postRateComment = async (id, vote) => {
  if (vote === '+') {
    trackVoteUp();
  } else {
    trackVoteDown();
  }

  const url = `/reader-comments/p/comment/${vote === '+' ? 'voteup' : 'votedown'}/${id}`;

  try {
    const response = await axios.post(url);

    if (response.status === 200) {
      return true;
    }

    logger.warn('postRateComment: warning', response.status);
  } catch (error) {
    logger.error('postRateComment: error', error);
  }

  return false;
};

export const postComment = async (articleId, message, replyTo = null) => {
  const url = '/reader-comments/p/comment/postCommentInline';

  try {
    if (replyTo) {
      trackCommentReplySubmit();
    } else {
      trackCommentSubmit();
    }

    const response = await axios.post(url, {
      assetId: articleId,
      message,
      postToFacebook: false,
      ...(replyTo ? {replyTo} : {}),
      source: 'desktop'
    });

    const {status, reason} = response.data;

    if (status === 'success') {
      clearCommentStore();

      if (replyTo) {
        trackCommentReplySubmitSuccess();
      } else {
        trackCommentSubmitSuccess();
      }

      return true;
    } else if (status === 'error' && reason === 'login') {
      saveCommentStore(articleId, message.trim(), replyTo);
      trackCommentLoginRedirect();

      window.location = LOGIN_URL + encodeURIComponent(window.location.href);

      return false;
    }

    logger.warn('postComment: response', status);
  } catch (error) {
    logger.error('postComment: error', error);
  }

  return false;
};
