export const isUserLoggedIn = () => window.DM && window.DM.isLoggedIn;

export const getUserId = () => {
  if (isUserLoggedIn()) {
    return window.DM.userId;
  }

  return null;
};

export const getUsername = () => {
  if (!isUserLoggedIn()) {
    return null;
  }

  const userData = window.DM.userData;

  if (!userData || typeof userData !== 'string') {
    return null;
  }

  try {
    const data = JSON.parse(atob(userData.replace(/"/g, '')).replace(/'/g, '"'));

    if (data && data.displayName) {
      return data.displayName;
    }
  } catch (error) {
    // ignore
  }

  return null;
};
