import React from 'react';
import {useProfileComment} from '../../context/ProfileCommentContext';
import CommentText from '../CommentText';
import ShareButtons from '../ShareButtons';
import VoteButtons from '../VoteButtons';
import CopyNotification from './CopyNotification';
import styles from './UserComment.module.scss';

export default ({comment}) => {
  const {id} = comment;
  const {copyId, onRate, ratings, showCopyNotification} = useProfileComment();

  return (
    <div className={styles.container}>
      <a
        className={styles.reportFlag}
        href={`/home/reportAbuseInComment.html?articleId=${comment.assetId}&commentId=${id}`}
      />

      <CommentText value={comment.message} />

      <div className={styles.commentFooter}>
        <ShareButtons comment={comment} onCopy={() => showCopyNotification(id)} />
        <VoteButtons comment={comment} onRate={onRate} ratings={ratings} />
      </div>

      {copyId === id && (<CopyNotification />)}
    </div>
  );
};
