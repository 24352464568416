import classnames from 'classnames';
import React from 'react';
import styles from './SendButton.module.scss';

export default ({disabled, isLoggedIn, loading, onClick}) => {
  if (loading) {
    return (
      <button className={classnames(styles.container, styles.loader)} disabled={true} onClick={() => {}} />
    );
  }

  if (isLoggedIn) {
    return (
      <button className={styles.container} disabled={disabled} onClick={onClick}>
        Comment
      </button>
    );
  }

  return (
    <button className={styles.container} disabled={disabled} onClick={onClick}>
      Log in to comment
    </button>
  );
};
