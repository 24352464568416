import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {asInitials} from '../common/format';
import styles from './Avatar.module.scss';

export default ({isLarge, value}) => {
  const [initials, setInitials] = useState(null);

  useEffect(() => {
    if (Boolean(value)) {
      setInitials(asInitials(value));
    }
  }, [value]);

  if (!value) {
    return (
      <div className={classnames(styles.container, isLarge && styles.main)}><div className={styles.avatarIcon} /></div>
    );
  }

  return (
    <div className={classnames(styles.container, isLarge && styles.main)}><span>{initials}</span></div>
  );
};
