/* eslint-disable import/max-dependencies */
import {logger} from '@mol-fe/mol-fe-client-logger';
import classnames from 'classnames';
import React, {useEffect, useState} from 'react';
import {postComment} from '../common/comments';
import {saveCommentStore} from '../common/cookies';
import {trackCommentClick, trackCommentLoginRedirect} from '../common/tracking';
import {isUserLoggedIn} from '../common/user';
import {useComment} from '../context/CommentContext';
import {useMount} from '../hooks/useMount';
import Avatar from './Avatar';
import styles from './InputForm.module.scss';
import SendButton from './SendButton';

const CHAR_LIMIT = 1000;
const LOGIN_URL = '/registration/login.html?reg_source=mweb_comment&targetUrl=';

export default ({reply = null}) => {
  const {addShadowComment, article, displayName, onClose, showErrorNotification} = useComment();
  const [value, setValue] = useState('');
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoggedIn] = useState(isUserLoggedIn());
  const isMounted = useMount();

  useEffect(() => {
    const sanitisedComment = value.trimStart();

    setInput(sanitisedComment);
  }, [value]);

  const onSendComment = async () => {
    if (loading) {
      return;
    }
    setLoading(true);

    const replyTo = reply ? `${reply.parentId}` : null;
    const trimmedInput = input.trim();

    if (!isLoggedIn) {
      saveCommentStore(article.id, trimmedInput, replyTo);
      trackCommentLoginRedirect();

      setTimeout(() => {
        window.location = LOGIN_URL + encodeURIComponent(window.location.href);
      }, 2000);

      return;
    }

    try {
      const success = await postComment(article.id, trimmedInput, replyTo);

      if (!isMounted.current) {
        return;
      }

      if (success) {
        if (!reply) {
          setInput('');
          setValue('');
        }

        addShadowComment(reply, trimmedInput);
        onClose();
      } else {
        showErrorNotification(reply);
      }
    } catch (error) {
      logger.warn('InputForm: err', error);
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  return (
    <div className={classnames(styles.container, reply && styles.replyBox)}>
      <div className={styles.textArea}>
        <div className={styles.textBox}>
          <Avatar isLarge={!Boolean(reply)} value={displayName} />
          <textarea
            className={styles.userInput}
            disabled={loading}
            maxLength={CHAR_LIMIT}
            onChange={(event) => setValue(event.target.value)}
            onFocus={() => trackCommentClick()}
            placeholder='Enter your comment...'
            value={value}
          />
        </div>
      </div>

      <div className={styles.submitArea}>
        {!reply && (
          <p>
            Comments are subject to our&nbsp;
            <a href='/home/house_rules.html' rel='nofollow' target='_blank' title='House Rules'>House rules</a> and&nbsp;
            <a href='https://www.dailymail.co.uk/home/article-1388146/Terms.html' rel='noopener noreferrer' target='_blank' title='Terms'>Terms</a>
          </p>
        )}

        <div className={styles.end}>
          {input.length > 0 && (
            <span className={styles.charCount}>{input.length}/{CHAR_LIMIT} characters</span>
          )}
          <SendButton
            disabled={input.length === 0}
            isLoggedIn={isLoggedIn}
            loading={loading}
            onClick={onSendComment}
          />
        </div>
      </div>
    </div>
  );
};
