import React, {useState} from 'react';
import classnames from 'classnames';
import {getUserId} from '../common/user';
import {useComment} from '../context/CommentContext';
import CommentHeader from './CommentHeader';
import CommentText from './CommentText';
import styles from './ShadowComment.module.scss';

export default ({message, isReply = false}) => {
  const {displayName} = useComment();
  const [userId] = useState(getUserId());

  return (
    <div className={classnames(styles.container, isReply && styles.reply)}>
      <CommentHeader datetime='Just now' displayName={displayName} location='default' userId={userId} />

      <CommentText value={message} />
    </div>
  );
};
