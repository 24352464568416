/* eslint-disable id-match */
import URL from 'url-parse';
import {ted} from '@mol-fe/mol-fe-tracking';
import {buildUrlParameters, getWindowDimensions, onSocialClick, windowOpen} from '../utils';
import {postToWhatsapp} from '../networks/whatsapp';
import {addParam, isMessageTooLong, truncateCommentBody} from './helpers';

export const facebook = (commentLink, commenterName = 'User', commentBody) => {
  const body = isMessageTooLong(commentBody) ? truncateCommentBody(commentBody) : commentBody;
  const quote = `${commenterName} commented on MailOnline: ${body}`;

  const fbLink = buildUrlParameters(
    {
      app_id: 146202712090395,
      link: addParam(commentLink, 'ito=facebook_share_comment_text'),
      quote,
      redirect_uri: 'https://i.dailymail.co.uk/i/pix/facebook/close.html'
    },
    'https://www.facebook.com/dialog/feed?'
  );

  onSocialClick('facebook', 'comment');
  ted.tedEvent('social-event', {'social-event': 'facebook-share-comment'});
  windowOpen(fbLink, '', getWindowDimensions(1000, 500));
};

export const twitter = (_, commenterName = 'User', commentBody) => {
  const ogUrl = document.querySelector('meta[property="og:url"]');
  const urlStr = ogUrl && ogUrl.getAttribute('content') || window.url || window.location.href;
  const parsed = new URL(urlStr);
  const urlWithIto = `${parsed.origin}${parsed.pathname}?ito=twitter_share_comment_text#comments`;

  const body = isMessageTooLong(commentBody) ? truncateCommentBody(commentBody) : commentBody;
  const quote = `${commenterName} commented on MailOnline: ${body}`;

  const twLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(quote)}&url=${encodeURIComponent(urlWithIto)}`;

  onSocialClick('twitter', 'comment');
  ted.tedEvent('social-event', {'social-event': 'twitter-tweet-comment'});
  windowOpen(twLink, '', getWindowDimensions(575, 400));
};

export const whatsapp = (commentLink, commenterName = 'User', commentBody) => {
  const body = isMessageTooLong(commentBody) ? truncateCommentBody(commentBody) : commentBody;
  const quote = `${commenterName} commented on MailOnline: ${body}`;

  ted.tedEvent('social-event', {'social-event': 'whatsapp-share-comment'});
  postToWhatsapp({
    placement: {
      origin: 'article',
      position: 'top'
    },
    shareObject: {
      forcedTitle: quote,
      forcedUrl: commentLink
    }
  });
};
