import React from 'react';
import {getIsUSGeoGroup} from '../../api';
import MailplusLogoWhite from './assets/mailplusLogoWhite.svg';
import MailplusLogoBlack from './assets/mailplusLogoBlack.svg';
import DailyMailplusLogoWhite from './assets/dailymailplusLogoWhite.svg';
import DailyMailplusLogoBlack from './assets/dailymailplusLogoBlack.svg';
import DailyMailLogoWhite from './assets/dailymailLogoWhite.svg';
import TimLogo from './assets/timLogo.svg';

const TIM = 'tim';
const MAIL_PLUS = 'mailPlus';

export const Logo = ({styles, color, type}) => {
  const isUS = getIsUSGeoGroup();
  let width = 276;
  let height = 58;
  let source = DailyMailLogoWhite;

  if (type === TIM) {
    source = TimLogo;
    width = 172;
    height = 64;
  } else if (type === MAIL_PLUS) {
    width = 162;
    height = 42;
    source = color === 'white' ? MailplusLogoWhite : MailplusLogoBlack;
  }

  if (type !== TIM && isUS) {
    width = 252;
    height = 46;

    source = color === 'white' ? DailyMailplusLogoWhite : DailyMailplusLogoBlack;
  }

  return (
    <img
      loading='lazy'
      className={styles || ''}
      src={source}
      width={width}
      height={height}
      alt='logo'
    />
  );
};
