import {logger} from '@mol-fe/mol-fe-client-logger';
import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';

const getShoutChannels = () => {
  try {
    if (window.DM.SHOUT && window.DM.SHOUT.shoutChannels) {
      return JSON.parse(window.DM.SHOUT.shoutChannels);
    }
  } catch (error) {
    logger.error('isShoutEnabled: error', error);
  }

  return null;
};

export const isShoutEnabled = () => {
  if (window.DM && window.DM.SHOUT && window.DM.SHOUT.isOldArticle) {
    return false;
  }

  const pageCriteria = getPageCriteria();
  const subchannel = pageCriteria && pageCriteria.subchannel;

  if (!subchannel) {
    return false;
  }

  const channels = getShoutChannels();

  if (!channels || !Array.isArray(channels.disabledChannels)) {
    return false;
  }

  return !channels.disabledChannels.includes(subchannel);
};
