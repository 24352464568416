import {logger} from '@mol-fe/mol-fe-client-logger';
import axios from 'axios';

// Validation messages
const COMPLAINT_MISSING = 'Please provide an explanation of your complaint';
const EMAIL_MISSING = 'Please enter a valid email address';
const EMAIL_INVALID = 'Please provide a valid email address';

// Validation REGEX
const EMAIL_REGEX = /^([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})?$/i;

// Other constants
const EMAIL = 'email';
const COMPLAINT = 'complaint';

// Cache DOM elements
const inputEmail = document.getElementById(EMAIL);
const inputComplaint = document.getElementById(COMPLAINT);
const abuseDetailsForm = document.getElementById('abuse-details');
const thankYouContainer = document.getElementsByClassName('thankyou')[0];
const failedContainer = document.getElementsByClassName('failed')[0];

// Error state
const errorState = {
  complaint: false,
  email: false
};

// Utils
const toggleDisplay = (elements, show) => {
  elements.forEach((element) => {
    if (element) {
      element.style.display = show ? 'block' : 'none';
    }
  });
};

const toggleErrorState = (field, wrapper, errorContainer, text, enabled) => {
  errorState[field] = enabled;
  if (wrapper && errorContainer) {
    wrapper.classList.remove(enabled ? 'succeed' : 'fail');
    wrapper.classList.add(enabled ? 'fail' : 'succeed');
    errorContainer.textContent = text;
    errorContainer.style.display = enabled ? 'block' : 'none';
  }
};

const validateEmail = () => {
  const value = inputEmail.value;
  const closestFieldWrapper = inputEmail.closest('.reg-fld');
  const alertContainer = inputEmail.nextElementSibling;

  if (value && EMAIL_REGEX.test(value)) {
    toggleErrorState(
      EMAIL,
      closestFieldWrapper,
      alertContainer,
      '',
      false
    );
  } else if (!value || !EMAIL_REGEX.test(value)) {
    toggleErrorState(
      EMAIL,
      closestFieldWrapper,
      alertContainer,
      value ? EMAIL_INVALID : EMAIL_MISSING,
      true
    );
  }
};

const validateComplaint = () => {
  const value = inputComplaint.value;
  const closestFieldWrapper = inputComplaint.closest('.reg-fld');
  const alertContainer = inputComplaint.nextElementSibling;

  if (value) {
    toggleErrorState(
      COMPLAINT,
      closestFieldWrapper,
      alertContainer,
      '',
      false
    );

    return;
  }

  toggleErrorState(
    COMPLAINT,
    closestFieldWrapper,
    alertContainer,
    COMPLAINT_MISSING,
    true
  );
};

const validateForm = () => {
  validateEmail();
  validateComplaint();
};

// event listeners
const formFields = [
  {
    element: inputEmail,
    name: EMAIL,
    validator: validateEmail
  },
  {
    element: inputComplaint,
    name: COMPLAINT,
    validator: validateComplaint
  }
];

formFields.forEach((formField) => {
  if (formField.element) {
    formField.element.addEventListener('focus', () => {
      formField.validator();
    });

    formField.element.addEventListener('blur', () => {
      if (errorState[formField.name]) {
        formField.element.nextElementSibling.style.display = 'none';
      }
    });

    formField.element.addEventListener('keyup', () => {
      formField.validator();
    });
  }
});

export const initialiseReportAbuseForm = () => {
  toggleDisplay([thankYouContainer, failedContainer], false);

  window.submitReportAbuseFunction = async () => {
    abuseDetailsForm.classList.add('submitting');
    const recaptchaResponse = window.grecaptcha.getResponse();

    if (recaptchaResponse.length === 0) {
      abuseDetailsForm.classList.remove('submitting');

      return;
    }

    const submitButton = abuseDetailsForm.querySelector('button[type="submit"]');

    submitButton.textContent = 'Loading ...';
    submitButton.disabled = true;

    validateForm();

    toggleDisplay([abuseDetailsForm, failedContainer], false);

    const payload = {
      commentId: document.getElementById('commentId').value,
      complaint: inputComplaint.value,
      email: inputEmail.value,
      recaptchaResponse
    };

    try {
      const response = await axios.post(
        '/reader-comments/p/postReportAbuseInComment.html',
        payload
      );

      const data = response.data;

      if (data.status === 'success') {
        document.querySelector('.reportId').innerHTML = data.reportId;
        toggleDisplay([abuseDetailsForm, failedContainer], false);
        toggleDisplay([thankYouContainer], true);
      } else {
        logger.error('Unable to submit comment report.', response);
        toggleDisplay([failedContainer], true);
        window.grecaptcha.reset();
        abuseDetailsForm.classList.remove('submitting');
      }
    } catch (error) {
      logger.error('Unable to submit comment report.', error);
      toggleDisplay([failedContainer], true);
    }
  };
};
