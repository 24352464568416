import Cookies from 'js-cookie';

export const getCommentStore = () => {
  const articleId = Cookies.get('commentAssetToSubmitCookie');
  const message = Cookies.get('commentMessageToSubmitCookie');
  const replyTo = Cookies.get('commentReplyToSubmitCookie');

  if (Boolean(articleId) && Boolean(message)) {
    return {
      articleId,
      message,
      replyTo
    };
  }

  return null;
};

export const saveCommentStore = (articleId, message, replyTo = null) => {
  const expires = new Date();

  expires.setHours(expires.getHours() + 1);

  Cookies.set('commentAssetToSubmitCookie', articleId, {
    expires,
    path: '/'
  });

  Cookies.set('commentMessageToSubmitCookie', message, {
    expires,
    path: '/'
  });

  if (replyTo) {
    Cookies.set('commentReplyToSubmitCookie', replyTo, {
      expires,
      path: '/'
    });
  }
};

export const clearCommentStore = () => {
  Cookies.set('commentAssetToSubmitCookie', '', {path: '/'});
  Cookies.set('commentMessageToSubmitCookie', '', {path: '/'});
  Cookies.set('commentReplyToSubmitCookie', '', {path: '/'});
};
