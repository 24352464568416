/* eslint-disable import/max-dependencies */
import {logger} from '@mol-fe/mol-fe-client-logger';
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import React from 'react';
import {render} from 'react-dom';
import {getJumpToComment, getStartTab} from './common/url';
import DesktopComments from './components/DesktopComments';
import ProfileComments from './components/ProfileComments/ProfileComments';
import UserProfileArrowFactor from './components/UserProfileArrowFactor';
import {initialiseReportAbuseForm} from './legacy/reportCommentForm';
import {isOwnProfile, isProfilePage, isReportCommentAbusePage, userIdFromPathName, COMMENT_CONTAINER_ID, openCommentsOverlay} from './utils';
import {getUsername} from './common/user';
// eslint-disable-next-line import/no-unassigned-import
import './legacy/reportCommentForm.scss';
// eslint-disable-next-line import/no-unassigned-import
import './index.scss';

const ARROW_FACTOR_ID = 'arrow-factor';

const getCommentCount = () => {
  const elem = document.querySelector('.readerCommentsCount');

  if (elem && elem.textContent) {
    const value = parseInt(elem.textContent, 0);

    if (!isNaN(value)) {
      return value;
    }
  }

  return 0;
};

const renderProfilePage = () => {
  const commentContainer = document.getElementById(COMMENT_CONTAINER_ID);
  const arrowFactorContainer = document.getElementById(ARROW_FACTOR_ID);

  if (commentContainer) {
    commentContainer.innerHTML = '';

    const loggedUserProfile = isOwnProfile();
    const username = loggedUserProfile ? getUsername() : 'User';
    const userId = loggedUserProfile ? window.DM.userId : userIdFromPathName();

    render(
      <ProfileComments userId={userId} username={username} />,
      commentContainer
    );
  }

  if (arrowFactorContainer) {
    arrowFactorContainer.innerHTML = '';
    render(
      <UserProfileArrowFactor />,
      arrowFactorContainer
    );
  }

  // This class (ArrowFactor) is meant to suppress the error caused by the script from cljrc-web template.
  // https://github.com/MailOnline/cljrc-web/blob/83651d1d9b34387e19b6fbf2a0cea96b3dcf1b9d/src/templates/arrowfactor.html#L99
  // When the cljrc-web arrow-factor template is disabled, this comment and the class ArrowFactor can be safely removed.
  class ArrowFactor {
    constructor (params) {
      // eslint-disable-next-line no-console
      logger.info('User Profile page Arrow Factor has been moved to async bundle. Legacy functionality has been deprecated.', params);
    }
  }

  window.DM.ArrowFactor = ArrowFactor;
};

const molFeReaderCommentsDesktop = () => {
  if (isProfilePage()) {
    renderProfilePage();

    return;
  }

  try {
    if (isReportCommentAbusePage()) {
      initialiseReportAbuseForm();
    }
  } catch (error) {
    logger.error('[mol-fe-desktop-comments] Unable to run initialiseReportAbuseForm', error);
  }

  const {pageType, readerComments, ccRender} = getPageCriteria();

  if (pageType !== 'article' || readerComments === 'X' || ccRender) {
    return;
  }

  const {articleId} = getPageMetadata();
  const startTab = getStartTab();
  const jumpToComment = getJumpToComment();
  const totalComments = getCommentCount();
  const commentContainer = document.getElementById(COMMENT_CONTAINER_ID);

  if (commentContainer) {
    commentContainer.innerHTML = '';

    const startLoadTime = Date.now();

    render(
      <DesktopComments
        article={{
          canComment: readerComments === 'W',
          id: articleId,
          totalComments
        }}
        jumpToComment={jumpToComment}
        startLoadTime={startLoadTime}
        startTab={startTab}
      />,
      commentContainer
    );

    document.documentElement.classList.remove('waiting-on-comments');

    if (window.location.hash && window.location.hash.startsWith('#comments')) {
      openCommentsOverlay();
    }
  }
};

// Make the ReaderComments object available globally.
molFeReaderCommentsDesktop();

export {
  molFeReaderCommentsDesktop
};
