import React, {useMemo} from 'react';
import Avatar from './Avatar';
import styles from './CommentHeader.module.scss';

const getSuffix = (datetime, location) => {
  if (typeof location === 'string' && location !== 'default') {
    return `${location}, ${datetime}`;
  }

  return datetime;
};

const getUserProfileUrl = (id, userAlias) => {
  const pathPart = typeof userAlias === 'string' ? userAlias.replace(/ /g, '-') : '';

  return `/registration/${id}/${pathPart}/profile.html`;
};

export default ({displayName, datetime, location, userId}) => {
  const suffix = useMemo(() => getSuffix(datetime, location), [datetime, location]);
  const url = useMemo(() => getUserProfileUrl(userId, displayName), [userId, displayName]);

  return (
    <div className={styles.container}>
      <Avatar value={displayName} />
      <span className={styles.userline}><a href={url}>{displayName}</a>, {suffix}</span>
    </div>
  );
};
