import React, {createContext, useContext, useState} from 'react';
import {getUsername} from '../common/user';
import {getStoredVotes, setStoredVotes} from '../common/vote';

const CommentContext = createContext();

export const CommentProvider = ({article, jumpToComment, children}) => {
  const [displayName] = useState(getUsername());
  const [reply, setShowReply] = useState(null);
  const [ratings, setRatings] = useState(getStoredVotes());
  const [shadowCommentStore, setShadowComments] = useState({});
  const [highlightComment] = useState(jumpToComment);
  const [notification, setNotification] = useState(null);

  const onReply = (value) => setShowReply(value);
  const onClose = () => setShowReply(null);

  const onRate = (id, value) => {
    setRatings({
      ...ratings,
      [id]: value
    });
    setStoredVotes(id, value);
  };

  const addShadowComment = (comment = null, message) => {
    const parentId = comment && comment.parentId ? comment.parentId : 'root';
    const replyId = comment && comment.replyId ? comment.replyId : 'root';
    const comments = shadowCommentStore[parentId] || [];

    setShadowComments({
      ...shadowCommentStore,
      [parentId]: [
        {
          id: Date.now(),
          message
        },
        ...comments
      ]
    });
    setNotification({
      replyId,
      type: 'success'
    });
  };

  const showErrorNotification = (comment = null) => setNotification({
    replyId: comment ? comment.replyId : 'root',
    type: 'error'
  });

  const showCopyNotification = (replyId) => setNotification({
    replyId,
    type: 'copy'
  });

  const clearNotification = () => setNotification(null);

  return (
    <CommentContext.Provider value={{
      addShadowComment,
      article,
      clearNotification,
      displayName,
      highlightComment,
      jumpToComment,
      notification,
      onClose,
      onRate,
      onReply,
      ratings,
      reply,
      shadowCommentStore,
      showCopyNotification,
      showErrorNotification
    }}>
      {children}
    </CommentContext.Provider>
  );
};

export const useComment = () => useContext(CommentContext);
