import React, {useEffect, useState} from 'react';
import {decode} from 'html-entities';
import styles from './CommentText.module.scss';

const CHAR_LIMIT = 250;

export default ({value}) => {
  const [message, setMessage] = useState(value);
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    if (value.length > CHAR_LIMIT) {
      setViewMore(true);
      setMessage(value.slice(0, CHAR_LIMIT));
    }
  }, []);

  const onSeeMore = () => {
    setViewMore(false);
    setMessage(value);
  };

  return (
    <p className={styles.container}>
      {decode(message)}
      {viewMore && (
        <span>.... <button className={styles.seeMore} onClick={onSeeMore}>See more</button></span>
      )}
    </p>
  );
};
