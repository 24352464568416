import React, {useEffect, useState} from 'react';
import {CommentProvider} from '../context/CommentContext';
import {isCommentsVisible} from '../utils';
import {trackCommentView} from '../common/tracking';
import Page from './Page';

export default ({article, jumpToComment, startTab, startLoadTime}) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [commentsViewed, setCommentsViewed] = useState(false);

  useEffect(() => {
    isCommentsVisible((visible) => {
      if (visible) {
        setCommentsViewed((prev) => {
          if (!prev) {
            trackCommentView();

            return true;
          }

          return prev;
        });
      }
    });
  }, [commentsViewed]);

  return (
    <CommentProvider article={article} jumpToComment={jumpToComment} >
      <div id='comments' />
      <Page firstLoad={firstLoad} setFirstLoad={setFirstLoad} startLoadTime={startLoadTime} tab={startTab} />
    </CommentProvider>
  );
};
