import classnames from 'classnames';
import React, {useEffect, useState} from 'react';
import styles from './LoadingDots.module.scss';

const DELAY_MS = 800;

export default () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setIndex((value) => (value + 1) % 3);
    }, DELAY_MS);

    return () => clearInterval(id);
  }, []);

  return (
    <div className={styles.container}>
      <span className={classnames(styles.dot, index === 0 && styles.active)} />
      <span className={classnames(styles.dot, index === 1 && styles.active)} />
      <span className={classnames(styles.dot, index === 2 && styles.active)} />
    </div>
  );
};
