import classnames from 'classnames';
import React, {useEffect} from 'react';
import {useComment} from '../context/CommentContext';
import styles from './Notification.module.scss';

const DURATION_MS = 3600;

const getText = (type) => {
  switch (type) {
  case 'success':
    return 'Thanks for sharing your comment.';
  case 'copy':
    return 'Link copied';
  case 'error':
  default:
    return 'Apologies - something went wrong.  Please try again later.';
  }
};

export default ({type}) => {
  const {clearNotification} = useComment();

  useEffect(() => {
    const id = setTimeout(clearNotification, DURATION_MS);

    return () => clearTimeout(id);
  }, []);

  return (
    <div className={classnames(
      styles.container,
      type === 'error' ? styles.error : styles.success
    )} >
      <span className={styles.icon} />
      <p className={styles.line}>{getText(type)}</p>
    </div>
  );
};
