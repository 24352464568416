import React, {useEffect} from 'react';
import {useProfileComment} from '../../context/ProfileCommentContext';
import styles from './CopyNotification.module.scss';

const DURATION_MS = 3600;

export default () => {
  const {clearNotification} = useProfileComment();

  useEffect(() => {
    const id = setTimeout(clearNotification, DURATION_MS);

    return () => clearTimeout(id);
  }, []);

  return (
    <div className={styles.container}>
      <span className={styles.icon} />
      <p className={styles.line}>Link copied</p>
    </div>
  );
};
