/* eslint-disable complexity */
import React, {useState, Fragment} from 'react';
import {pageEvents} from '@mol-fe/mol-fe-tracking';
import {setToStorage} from '../../storage';
import {CUSTOMER_SERVICE_URL} from '../../config';
import {RestorePurchaseModal} from '../RestorePurchaseModal';
import SubscriptionStatus from './SubscriptionStatus';
import styles from './styles.scss';

export const ProfileSubscription = ({
  restorePurchaseUrl,
  purchaseUrl,
  hasMailplus,
  hasEssential,
  hasLinkedAccount,
  mailplusEmail,
  manageSubscriptionUrl,
  canRestorePurchase,
  entitlementFetchError,
  isUS
}) => {
  const [isRestorePurchaseModalOpen, setIsRestorePurchaseModalOpen] = useState(false);

  const handlerRestorePurchaseLinkClick = (event) => {
    const flowVariant = 'account restore purchase';

    event.preventDefault();
    setIsRestorePurchaseModalOpen(true);
    setToStorage('restore-meta', {flowVariant});
    pageEvents.publish(pageEvents.PAYWALL_RESTORE_PURCHASE_CLICK, {flowVariant});
  };

  const handleClickPurchase = () => {
    setToStorage('return-url', location.href);
    setToStorage('purchase-meta', {
      referringModule: 'profile',
      referringPageType: 'profile'
    });
    pageEvents.publish(pageEvents.PAYWALL_PROFILE_SIGNUP_CLICK);
  };

  const hasProduct = hasMailplus || hasEssential;
  const mailPlusProductName = isUS ? 'DailyMail+ monthly' : 'Mail+ monthly';

  return (
    <Fragment>
      <div className={`prfl-view-optn m-b-15 ${styles.wrapper}`}>
        <h2 className='f-16 m-t-15'>Mail Subscriptions</h2>

        <div className={styles.subscriptions}>
          <SubscriptionStatus title={mailPlusProductName} isActive={hasMailplus} />
          {(!isUS || hasEssential) && <SubscriptionStatus title='Mail Essential' isActive={hasEssential} />}
        </div>

        {hasLinkedAccount && mailplusEmail &&
          <div className={styles.statusWrapper}>
            <div>Your subscriber email address: <span className={styles.subscriberEmail}>{mailplusEmail}</span></div>
          </div>
        }
        <div className={styles.buttons}>
          {hasProduct && <a className={styles.button} target='_blank' href={manageSubscriptionUrl} rel='noopener'>Manage my subscription</a>}
          {!hasProduct && purchaseUrl && <a className={styles.button} target='_blank' href={purchaseUrl} rel='noopener' onClick={handleClickPurchase}>Purchase subscription</a>}
          <a className={styles.button} target='_blank' rel='nofollow noopener' href={CUSTOMER_SERVICE_URL}>Help & FAQs</a>
        </div>
        {!hasProduct && entitlementFetchError &&
          <div className={styles.restorePurchase}>
            Please <a href={restorePurchaseUrl}>log in again</a> to access your subscription
          </div>}
        {!hasProduct && !entitlementFetchError && canRestorePurchase &&
          <div className={styles.restorePurchase}>
            Already a subscriber? <a target='_blank' href={restorePurchaseUrl} rel='noopener' onClick={handlerRestorePurchaseLinkClick}>Click here</a>
          </div>
        }
      </div>
      {isRestorePurchaseModalOpen &&
        <RestorePurchaseModal
          restorePurchaseUrl={restorePurchaseUrl}
          onClose={() => setIsRestorePurchaseModalOpen(false)}
        />
      }
    </Fragment>
  );
};
