import {logger} from '@mol-fe/mol-fe-client-logger';

const MAX_STORAGE_RATINGS = 100;
const RATING_STORE = 'molCommentRatings';

export const asVoteRatings = ({voteCount, voteRating}) => {
  const voteDiff = (voteCount - Math.abs(voteRating)) / 2;

  return {
    downVotes: voteRating < 0 ? Math.abs(voteRating - voteDiff) : voteDiff,
    upVotes: voteRating >= 0 ? voteRating + voteDiff : voteDiff
  };
};

const getRatings = () => {
  try {
    const value = localStorage.getItem(RATING_STORE);

    if (value) {
      const parts = value.split(',');

      if (parts.length > 0) {
        return parts;
      }
    }
  } catch (error) {
    logger.error('getStoredVotes: failed', error);
  }

  return [];
};

export const getStoredVotes = () => {
  const value = getRatings();

  return value.reduce((acc, item) => {
    const match = item.match(/(\d+)([+-])/);

    if (match) {
      // eslint-disable-next-line no-unused-vars
      const [_, id, sign] = match;

      acc[id] = sign;
    }

    return acc;
  }, {});
};

export const setStoredVotes = (id, rating) => {
  const value = getRatings();
  const newRating = `${id}${rating}`;

  value.push(newRating);

  if (value.length > MAX_STORAGE_RATINGS) {
    value.shift();
  }

  try {
    localStorage.setItem(RATING_STORE, value.join(','));
  } catch (error) {
    logger.error('setStoredVotes: failed', error);
  }
};
