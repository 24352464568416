/* eslint-disable promise/prefer-await-to-callbacks */

import axios from 'axios';
import {logger} from '@mol-fe/mol-fe-client-logger';

const REGISTRATION_PATH_NAME = '/registration/profile.html';

export const COMMENT_CONTAINER_ID = 'reader-comments';

export const isProfilePage = () => {
  const location = window.location.toString();

  return location.includes('/profile.html');
};

export const isReportCommentAbusePage = () => {
  const location = window.location.toString();

  return location.includes('/reportAbuseInComment.html');
};

export const getArrowFactor = (period, userId) =>
  axios.get(`/reader-comments/p/user/arrowfactor/${userId}?period=${period}`);

export const isOwnProfile = () => (
  window &&
  window.DM &&
  window.DM.isLoggedIn &&
  window.location &&
  window.location.pathname === REGISTRATION_PATH_NAME
);

export const userIdFromPathName = () => {
  const pathName = window && window.location && window.location.pathname;

  if (pathName && pathName.includes('profile.html')) {
    const pathParts = pathName.split('/');

    if (pathParts.length && pathParts[2]) {
      const id = pathParts[2];

      if (!isNaN(Number(id))) {
        return id;
      }
    }
  }

  return null;
};

export const scrollToComments = () => {
  const commentContainer = document.getElementById(COMMENT_CONTAINER_ID);

  if (commentContainer) {
    commentContainer.scrollIntoView({behavior: 'smooth'});
  }
};

export const sanitiseComment = (comment) => {
  if (typeof comment !== 'string') {
    throw new TypeError('Expected a string input');
  }

  // Preserve newline and carriage return while removing other non-ASCII characters
  return comment.replace(/[^\u0020-\u007E\n\r]/g, '');
};

export const openCommentsOverlay = async () => {
  try {
    const waitForOverlayModule = () => new Promise((resolve, reject) => {
      const checkInterval = 200;
      const maxAttempts = 50;

      let attempts = 0;

      const interval = setInterval(() => {
        const waitedOverlayModule = window?.DM?.molFeReaderCommentsOverlay?.loaded;

        if (waitedOverlayModule) {
          clearInterval(interval);
          resolve(waitedOverlayModule);
        } else if (attempts >= maxAttempts) {
          clearInterval(interval);
          reject(new Error('overlayModule is not available'));
        }

        attempts++;
      }, checkInterval);
    });

    const overlayModule = await waitForOverlayModule();

    if (overlayModule && overlayModule.openOverlay) {
      overlayModule.openOverlay();
    } else {
      logger.warn('openOverlay function is not available.');
    }
  } catch (error) {
    logger.warn('openCommentsOverlay: error', error);
  }
};

export const isCommentsVisible = (callback) => {
  const observer = new IntersectionObserver((entries) => {
    const isVisible = entries.some((entry) => entry.isIntersecting);

    callback(isVisible);
  }, {threshold: 0.1});

  const elements = [
    document.getElementById('reader-comments-in-overlay'),
    document.getElementById('reader-comments')
  ].filter(Boolean);

  elements.forEach((el) => observer.observe(el));

  return () => elements.forEach((el) => observer.unobserve(el));
};
