import {logger} from '@mol-fe/mol-fe-client-logger';
import {pageEvents, ted} from '@mol-fe/mol-fe-tracking';

const trackEvent = (eventKey, data) => {
  try {
    pageEvents.publish(eventKey, data);
  } catch (error) {
    logger.error('trackEvent: error', eventKey, error);
  }
};

export const trackCommentClick = () => trackEvent(pageEvents.COMMENTS_CLICK);
export const trackCommentImpression = () => trackEvent(pageEvents.COMMENTS_IMPRESSION);
export const trackCommentReportClick = () => trackEvent(pageEvents.COMMENTS_REPORT_CLICK);
export const trackCommentReplyClick = () => trackEvent(pageEvents.COMMENTS_REPLY_CLICK);
export const trackCommentReplySubmit = () => trackEvent(pageEvents.COMMENTS_REPLY_SUBMIT);
export const trackCommentReplySubmitSuccess = () => trackEvent(pageEvents.COMMENTS_REPLY_SUBMIT_SUCCESS);
export const trackCommentSubmit = () => trackEvent(pageEvents.COMMENTS_SUBMIT_COMMENT);
export const trackCommentSubmitSuccess = () => trackEvent(pageEvents.COMMENTS_SUBMIT_SUCCESS);
export const trackCommentLoginRedirect = () => trackEvent(pageEvents.COMMENTS_LOGIN_REDIRECT);
export const trackCommentView = () => trackEvent(pageEvents.COMMENTS_VIEW);
export const trackVoteDown = () => trackEvent(pageEvents.COMMENTS_DOWNVOTE);
export const trackVoteUp = () => trackEvent(pageEvents.COMMENTS_UPVOTE);
export const trackCommentLoadTime = (value) => trackEvent(pageEvents.COMMENTS_LOAD_TIME, {value});
export const trackCommentPaginationClick = (label) => trackEvent(pageEvents.COMMENTS_PAGINATION_CLICK, {label});
export const trackCommentTab = (tab) => trackEvent(pageEvents.PAGE_COMMENT_TAB_SWAPPED, {
  contentType: 'commenttab',
  tab
});

export const tedTrackReaderComments = (id, message, status) => {
  ted.tedEvent('reader-comments', {
    params: {
      params: `assetId:${id},message:${message}`,
      status
    },
    status
  });
};
