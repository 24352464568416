import {logger} from '@mol-fe/mol-fe-client-logger';

export const copyToClipboard = async (id) => {
  const url = `https://${window.location.host}/reader-comments/p/comment/link/${id}`;

  try {
    await navigator.clipboard.writeText(url);

    return true;
  } catch (error) {
    logger.warn('copyToClipboard: error', error);
  }

  return false;
};
