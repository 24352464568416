/* eslint-disable import/max-dependencies, complexity */
import classnames from 'classnames';
import React, {useEffect, useState} from 'react';
import {loadCommentReplies} from '../common/comments';
import {useComment} from '../context/CommentContext';
import {useMount} from '../hooks/useMount';
import {trackCommentReplyClick, trackCommentReportClick} from '../common/tracking';
import styles from './Comment.module.scss';
import CommentHeader from './CommentHeader';
import CommentLayout from './CommentLayout';
import CommentReplyList from './CommentReplyList';
import CommentText from './CommentText';
import InputForm from './InputForm';
import Notification from './Notification';
import ShareButtons from './ShareButtons';
import VoteButtons from './VoteButtons';

const hasMoreCommentCount = (replies) => replies.totalCount - replies.comments.length;

export default ({comment, lastReply, parentId, updateComments, type = null}) => {
  const {id, message, replies} = comment;

  const {
    article,
    highlightComment,
    notification,
    onReply,
    onRate,
    ratings,
    reply,
    shadowCommentStore,
    showCopyNotification,
    showErrorNotification
  } = useComment();
  const isMounted = useMount();
  const [prompt, setShowPrompt] = useState({
    comments: replies.comments,
    type: 'normal'
  });
  const [loading, setLoading] = useState(false);
  const [shadowComments, setShadowComments] = useState(null);

  useEffect(() => {
    const value = hasMoreCommentCount(replies);

    if (value > 0) {
      setShowPrompt({
        comments: replies.comments,
        count: value,
        type: 'viewMore'
      });
    }
  }, [replies]);

  useEffect(() => {
    if (shadowCommentStore[id]) {
      setShadowComments(shadowCommentStore[id]);
    }
  }, [shadowCommentStore[id]]);

  const onViewReplies = async () => {
    if (loading || prompt.type !== 'viewMore') {
      return;
    }

    if (replies.comments.length >= replies.totalCount) {
      setShowPrompt({
        comments: replies.comments,
        count: 0,
        type: 'collapse'
      });

      return;
    }

    setLoading(true);

    const newReplies = await loadCommentReplies(id, replies.comments.length);

    if (isMounted.current) {
      if (Boolean(newReplies)) {
        const updatedReplies = [...newReplies, ...replies.comments];

        updateComments(id, updatedReplies);

        setShowPrompt({
          cachedComments: updatedReplies,
          comments: updatedReplies,
          count: replies.totalCount - updatedReplies.length,
          type: 'collapse'
        });
        setLoading(false);
      } else {
        showErrorNotification();
      }
    }
  };

  const onCollapse = () => {
    if (prompt.type !== 'collapse') {
      return;
    }

    const COLLAPSED_LIMIT = 2;
    const updatedList = replies.comments.slice(-COLLAPSED_LIMIT);

    setShowPrompt({
      cachedComments: replies.comments,
      comments: updatedList,
      count: replies.comments.length > COLLAPSED_LIMIT ? replies.comments.length - COLLAPSED_LIMIT : 0,
      type: 'viewMore'
    });
  };

  const onReplyClick = () => {
    trackCommentReplyClick();

    onReply({
      displayName: comment.userAlias,
      parentId,
      replyId: id,
      text: message
    });
  };

  const onReportClick = (event) => {
    event.preventDefault();
    trackCommentReportClick();

    setTimeout(() => {
      window.location = `/home/reportAbuseInComment.html?articleId=${article.id}&commentId=${id}`;
    }, 2000);
  };

  const isParent = replies.totalCount > 0 || Boolean(shadowComments);

  return (
    <React.Fragment>
      <div className={classnames(styles.container,
        isParent && styles.parent,
        type === 'reply' && styles.reply,
        lastReply && styles.noPath,
        (highlightComment && highlightComment.id === id) && styles.highlightComment,
      )}>
        <CommentLayout
          headComponent={
            <CommentHeader
              datetime={comment.formattedDateAndTime}
              displayName={comment.userAlias}
              location={comment.userLocation}
              userId={comment.userIdentifier}
            />
          }
          type={isParent ? 'parent' : type}
        >
          <a
            className={styles.reportFlag}
            href={`/home/reportAbuseInComment.html?articleId=${article.id}&commentId=${id}`}
            onClick={onReportClick}
          />

          <CommentText value={message} />

          <div className={styles.commentFooter}>
            <div className={styles.commentAction}>
              {article.canComment && (
                <button className={styles.buttonText} onClick={onReplyClick}>Reply</button>
              )}
              <ShareButtons comment={comment} onCopy={() => showCopyNotification(comment.id)} />
            </div>

            <VoteButtons comment={comment} onRate={onRate} ratings={ratings} />
          </div>

          {reply && reply.replyId === id && (
            <InputForm reply={reply} />
          )}
        </CommentLayout>

        {notification && notification.replyId === id && (
          <Notification type={notification.type} />
        )}
      </div>

      {isParent && (
        <CommentReplyList
          loading={loading}
          onCollapse={onCollapse}
          onViewReplies={onViewReplies}
          parentId={parentId}
          prompt={prompt}
          shadowComments={shadowComments}
        />
      )}
    </React.Fragment>
  );
};
