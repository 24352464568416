import React from 'react';
import styles from './CommentLayout.module.scss';

export default ({type, headComponent = null, children}) => {
  switch (type) {
  case 'reply':
    return (
      <React.Fragment>
        <div className={styles.replyPath} />
        <div className={styles.comment}>
          {headComponent}
          {children}
        </div>
      </React.Fragment>
    );
  case 'parent':
    return (
      <React.Fragment>
        {headComponent}
        <div className={styles.parentComment}>
          <div className={styles.spacing} />
          <div className={styles.comment}>{children}</div>
        </div>
      </React.Fragment>
    );
  case 'normal':
  default:
    return (
      <React.Fragment>
        {headComponent}
        {children}
      </React.Fragment>
    );
  }
};
