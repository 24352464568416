import React from 'react';
import {render} from 'react-dom';
import {getIsUSGeoGroup} from '../api';
import {
  CAME_FROM_PURCHASE_ESSENTIAL,
  CMP_CONVERSION,
  getMountElement,
  handleUnmount,
  handleCmpTracking
} from './constantsAndUtils';
import NewUserModal from './components/NewUserModal';

const renderNewUserModal = (
  cameFromPurchase,
  userHasMailPlus,
  userHasEssential,
  userHasAdLite,
  mailPlusEmail
) => {
  const mountElement = getMountElement();
  const isEssentialPurchase = userHasEssential && cameFromPurchase === CAME_FROM_PURCHASE_ESSENTIAL;
  const isUS = getIsUSGeoGroup();

  const onClose = () => {
    if (userHasAdLite || isEssentialPurchase) {
      window.location.reload();
    } else {
      handleUnmount(mountElement);
    }
  };

  render(
    <NewUserModal
      userHasMailPlus={userHasMailPlus}
      userHasEssential={userHasEssential}
      mailPlusEmail={mailPlusEmail}
      cameFromPurchase={cameFromPurchase}
      onClose={onClose}
      isUS={isUS}
    />,
    mountElement
  );

  if (isEssentialPurchase) {
    handleCmpTracking(CMP_CONVERSION);
  }
};

export default renderNewUserModal;
