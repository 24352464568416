import classnames from 'classnames';
import React from 'react';
import {getReplyText} from '../common/format';
import Comment from './Comment';
import CommentLayout from './CommentLayout';
import styles from './CommentReplyList.module.scss';
import LoadingDots from './LoadingDots';
import ShadowComment from './ShadowComment';

export default ({loading, onCollapse, onViewReplies, parentId, prompt, shadowComments}) => {
  const showLastReply = prompt.type !== 'viewMore' && !shadowComments;

  return (
    <React.Fragment>
      {prompt.type === 'collapse' && (
        <div className={styles.collapse}>
          <button onClick={onCollapse}>
            Collapse replies<span className={styles.chevronIcon} />
          </button>
        </div>
      )}

      {prompt.comments.map((comment, index) => (
        <Comment
          comment={comment}
          key={comment.id}
          lastReply={showLastReply && index === prompt.comments.length - 1}
          parentId={parentId}
          type='reply'
        />
      ))}

      {shadowComments && shadowComments.map((cmt, index) => (
        <div
          className={classnames(
            styles.shadowComment,
            (prompt.type !== 'viewMore' && index === shadowComments.length - 1) && styles.last
          )}
          key={cmt.id}>
          <CommentLayout type='reply'>
            <ShadowComment isReply={true} message={cmt.message} />
          </CommentLayout>
        </div>
      ))}

      {loading && (
        <div className={styles.loader}>
          <LoadingDots />
        </div>
      )}

      {prompt.type === 'viewMore' && (
        <div className={styles.viewMore}>
          <button onClick={onViewReplies}>
            {getReplyText(prompt.count)}<span className={styles.chevronIcon} />
          </button>
        </div>
      )}
    </React.Fragment>
  );
};
