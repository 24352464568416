import React from 'react';
import shareComment from '@mol-fe/mol-fe-social/src/desktop/readerComments/shareComment';
import {copyToClipboard} from '../common/share';
import styles from './ShareButtons.module.scss';

export default ({comment, onCopy}) => {
  const onCopyClipboard = async () => {
    const copied = await copyToClipboard(comment.id);

    if (copied) {
      onCopy();
    }
  };

  return (
    <div className={styles.container}>
      <button onClick={() => shareComment('facebook', comment)}>
        <span className={styles.meta} />
      </button>
      <button onClick={() => shareComment('xTwitter', comment)}>
        <span className={styles.xTwitter} />
      </button>
      <button onClick={() => shareComment('whatsapp', comment)}>
        <span className={styles.whatsapp} />
      </button>
      <button onClick={onCopyClipboard}>
        <span className={styles.copy} />
      </button>
    </div>
  );
};
