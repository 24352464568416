import React, {createContext, useContext, useState} from 'react';
import {getStoredVotes, setStoredVotes} from '../common/vote';

const ProfileCommentContext = createContext();

export const ProfileCommentProvider = ({children}) => {
  const [copyId, setCopyId] = useState(null);
  const [ratings, setRatings] = useState(getStoredVotes());

  const clearNotification = () => setCopyId(null);
  const showCopyNotification = (id) => setCopyId(id);

  const onRate = (id, value) => {
    setRatings({
      ...ratings,
      [id]: value
    });
    setStoredVotes(id, value);
  };

  return (
    <ProfileCommentContext.Provider value={{
      clearNotification,
      copyId,
      onRate,
      ratings,
      showCopyNotification
    }}>
      {children}
    </ProfileCommentContext.Provider>
  );
};

export const useProfileComment = () => useContext(ProfileCommentContext);
