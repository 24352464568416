export const formatNumber = (value) => {
  if (value < 1000) {
    return value.toString();
  }

  const updatedValue = value / 1000;
  const roundedValue = Math.round(updatedValue * 10) / 10;

  return `${roundedValue}K`;
};

export const asInitials = (value) => {
  const updatedValue = value.trim();

  if (updatedValue.length === 0) {
    return null;
  }

  const parts = updatedValue.split(' ');

  if (parts.length === 1) {
    return parts[0][0];
  }

  return parts[0][0] + parts[1][0];
};

export const getReplyText = (count) => {
  if (count === 1) {
    return 'View 1 reply';
  }

  return `View ${count} replies`;
};
