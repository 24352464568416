const TABS = ['bestRated', 'oldest', 'worstRated', 'newest'];

export const getStartTab = () => {
  const params = new URLSearchParams(window.location.search);
  const sort = params.get('startCommentsSort');

  if (TABS.includes(sort)) {
    return sort;
  }

  return 'newest';
};

export const getJumpToComment = () => {
  const params = new URLSearchParams(window.location.search);
  const jumpTo = params.get('jumpTo');
  const value = params.get('offset');

  if (jumpTo && jumpTo.match(/comment-\d+/) && value) {
    const id = parseInt(jumpTo.replace('comment-', ''), 10);
    const offset = parseInt(value, 10);

    if (!isNaN(id) && !isNaN(offset)) {
      return {
        id,
        offset
      };
    }
  }

  return null;
};
