import {logger} from '@mol-fe/mol-fe-client-logger';
import {facebook, twitter, whatsapp} from '../../readerComments/shareToNetwork';

const shareComment = (platform, commentData) => {
  if (!commentData && !commentData.id) {
    logger.error('shareComment has been invoked without a valid commentData object.');

    return;
  }

  const commentURL = `https://${window.location.host}/reader-comments/p/comment/link/${commentData.id}`;
  const publicData = {
    commentBody: commentData.message,
    commenterName: commentData.userAlias,
    commentLink: commentURL,
    shortUrl: commentURL
  };

  const {commentLink, commenterName, commentBody} = publicData;

  if (platform === 'facebook') {
    facebook(commentLink, commenterName, commentBody);
  } else if (platform === 'xTwitter') {
    twitter(commentLink, commenterName, commentBody);
  } else if (platform === 'whatsapp') {
    whatsapp(commentLink, commenterName, commentBody);
  }
};

export default shareComment;
