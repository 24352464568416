/* eslint-disable max-len */
/* eslint-disable complexity */
import React, {useEffect, useState, useRef, useMemo} from 'react';
import {useComment} from '../context/CommentContext';
import {formatNumber} from '../common/format';
import Comment from './Comment';
import ShadowComment from './ShadowComment';
import styles from './CommentList.module.scss';

export default ({canComment, comments, commentsCount, loading, onShowMore, showMore, updateComments, tab}) => {
  const {shadowCommentStore} = useComment();
  const [shadowComments, setShadowComments] = useState(null);
  const prevTabRef = useRef(tab);
  const [isTabChanging, setIsTabChanging] = useState(false);

  useEffect(() => {
    if (shadowCommentStore.root) {
      setShadowComments(shadowCommentStore.root);
    }
  }, [shadowCommentStore.root]);

  useEffect(() => {
    if (prevTabRef.current !== tab) {
      setIsTabChanging(true);
      prevTabRef.current = tab;
    }
  }, [tab]);

  useEffect(() => {
    if (!loading && isTabChanging) {
      setIsTabChanging(false);
    }
  }, [loading, isTabChanging]);

  const totalCommentsWithReplies = useMemo(() =>
    comments.reduce((acc, comment) => acc + comment?.replies?.totalCount, comments.length),
  [comments]);
  const remainingComments = Math.max(0, commentsCount - totalCommentsWithReplies);

  const noCommentsMessage1 = tab === 'myComments' ? 'What do you think?' : 'No one seems to have shared their thoughts on this topic yet';
  const noCommentsMessage2 = tab === 'myComments' ? 'Let us know in the comments' : 'Leave a comment so your voice will be heard first.';

  const loadingMessage1 = tab === 'myComments' ? 'Thank you for your patience' : 'Loading comments...';
  const loadingMessage2 = tab === 'myComments' ? 'We are working on loading your comments, please check back later.' : '';

  const showMoreButtonText = tab === 'myComments' ? 'Show More Comments' : `Show ${formatNumber(remainingComments)} More Comments`;

  if ((comments.length === 0 && loading) || isTabChanging) {
    return (
      <div className={styles.container}>
        <span className={styles.icon} />

        <b>{loadingMessage1}</b>

        {loadingMessage2 && (
          <p>{loadingMessage2}</p>
        )}
      </div>
    );
  }

  if (comments.length === 0 && !shadowComments) {
    return (
      <div className={styles.container}>
        <span className={styles.icon} />

        <b>{noCommentsMessage1}</b>

        {canComment && (
          <p>{noCommentsMessage2}</p>
        )}
      </div>
    );
  }

  return (
    <React.Fragment>
      {shadowComments && shadowComments.map(({id, message}) => (
        <ShadowComment key={id} message={message} />
      ))}

      {comments.length > 0 && (
        <React.Fragment>
          {comments.map((comment) => (
            <Comment
              comment={comment}
              key={comment.id}
              parentId={comment.id}
              updateComments={updateComments}
            />
          ))}

          {showMore && (
            <button
              className={styles.showMoreBtn}
              disabled={loading}
              onClick={() => onShowMore()}>
              {showMoreButtonText}
            </button>
          )}
        </React.Fragment>
      )}

      <p className={styles.viewsExpressed}>
        The opinions and views expressed in the comments section are solely those of the individual users and do not represent or reflect the opinions, views, or positions of MailOnline. MailOnline does not endorse, support, or verify the accuracy of any user-generated content.
      </p>
    </React.Fragment>
  );
};
