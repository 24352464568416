import classnames from 'classnames';
import React, {useEffect, useState} from 'react';
import {postRateComment} from '../common/comments';
import {formatNumber} from '../common/format';
import {asVoteRatings} from '../common/vote';
import VoteArrowIcon from './VoteArrowIcon';
import styles from './VoteButtons.module.scss';

export default ({comment, ratings, onRate}) => {
  const [vote, setVote] = useState(ratings[comment.id]);

  const [voteRating] = useState(asVoteRatings(comment));
  const [counters, setCounters] = useState(voteRating);

  const onVote = async (value) => {
    if (vote) {
      return;
    }

    setVote(value);
    onRate(comment.id, value);
    await postRateComment(comment.id, value);
  };

  useEffect(() => {
    setCounters({
      downVotes: vote === '-' ? voteRating.downVotes + 1 : voteRating.downVotes,
      upVotes: vote === '+' ? voteRating.upVotes + 1 : voteRating.upVotes
    });
  }, [vote]);

  return (
    <div className={styles.container}>
      <label>Click to rate</label>
      <button
        className={classnames(styles.upVote, vote === '+' && styles.voted)}
        disabled={Boolean(vote)}
        onClick={() => onVote('+')}>
        <VoteArrowIcon />
        <span>{formatNumber(counters.upVotes)}</span>
      </button>
      <button
        className={classnames(styles.downVote, vote === '-' && styles.voted)}
        disabled={Boolean(vote)}
        onClick={() => onVote('-')}>
        <VoteArrowIcon />
        <span>{formatNumber(counters.downVotes)}</span>
      </button>
    </div>
  );
};
